html,
body {
    width: 100%;
    height: 100%;
    /* background-color: #05103a; */
    background-image: url("../public/res/background/background-4.png");
    background-size: cover;
    background-position: 0;
    background-repeat: repeat;
    overflow: hidden;
}

html,
body :fullscreen,
::backdrop {
    /* background-color: #05103a; */
    background-image: url("../public/res/background/background-4.png");
    background-size: cover;
}

@font-face {
    font-family: "Cramps";
    src: local("Cramps"),
        url(../public/res/fonts/cramps/Cramps.ttf) format("truetype");
}

@font-face {
    font-family: "grisly";
    src: local("grisly"),
        url(../public/res/fonts/grisly/grisly.ttf) format("truetype");
}

@font-face {
    font-family: "varela";
    src: local("varela"),
        url(../public/res/fonts/varela/varela.ttf) format("truetype");
}

@font-face {
    font-family: "navitism";
    src: local("navitism"),
        url(../public/res/fonts/navitism/Navitism-Regular.otf) format("truetype");
}

h1 {
    font-size: 2.7rem;
    color: aliceblue;
    font-family: "navitism", sans-serif;
}

h2 {
    color: #646d90;
    font-size: 2.2rem;
    font-family: "navitism", sans-serif;
}

h3 {
    color: aliceblue;
    font-size: 1.7rem;
    font-family: "navitism", sans-serif;
}

h4 {
    color: aliceblue;
    font-size: 1.0rem;
    line-height: 1.9rem;
    font-family: "navitism", sans-serif;
}

.gridContainer {
    display: grid;
    grid-template-areas:
        "leaderboard division"
        "leaderboard blinds"
        "leaderboard timer"
        "leaderboard sponsor-logos"
        "leaderboard none";

    grid-column-gap: 5vw;
    grid-row-gap: 5vh;
    /* background-color: #2196f3; */
    padding: 0 3vw 0 3vw;
    justify-content: center;
    margin-top: 10vh;
}

.gridContainerFinal {
    display: grid;
    grid-template-areas:
        "blinds"
        "timer";
    grid-column-gap: 5vw;
    grid-row-gap: 5vh;
    padding: 0 3vw 0 3vw;
    justify-content: center;
    margin-top: 25vh;
    transform: scale(1.5);
    justify-content: center;
}

.leaderboard,
.header {
    grid-area: leaderboard;
    /* background-color: black; */
}

.header,
.logo,
.fullScreenIcon {
    margin-top: 10vh;
}

.division,
.blinds,
.timer,
.sponsorLogos {
    grid-area: division;
    display: "flex";
    align-items: center;
    justify-content: center;
}

.blinds {
    grid-area: blinds;
}

.timer {
    grid-area: timer;
}

.sponsorLogos {
    grid-area: sponsor-logos;
}

.sponsorLogoImage {
    width: 21vw;
    text-align: center;
}

.sponsorLogoImage img {
    width: 95%;
    margin: 1vh 0 1vh 0;
    opacity: 1;
}

.sponsorLogoImageFinal img {
    width: 95%;
    margin: 1vh 0 1vh 0;
    opacity: 1;
}

.cardContainer {
    /* background: #101c43; */
    background: #040b24;
    border-radius: 9.96378px;
    display: flex;

    flex: none;
    order: 1;
    flex-grow: 0;
    align-items: center;
    justify-content: left;

    padding: 1vh 1vw 1vh 1vw;
    min-width: 21vw;
    max-width: 25vw;
}

.cardElement {
    padding: 0 0vw 0 2vw;
}

.sponsorPage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.sponsorPageItems {
    display: inline-block;
    align-items: center;
    justify-content: center;
    justify-self: center;
    text-align: center;
    height: 90%;
}

.sponsorPostImage {
    height: 80%;
}

.fullScreenIcon {
    position: absolute;
    left: 90vw;
    top: 0;
    z-index: 2;
    color: darkorange;
    font-size: "large";
    cursor: pointer;
}

.fullScreenIcon:hover {
    color: orange;
    transform: scale(1.2);
}

.logo {
    left: 7vw;
    top: -5vh;
    position: absolute;
    height: 10vw;
    size: 100px;
    border: 1px solid #041128;
    box-shadow: -5px 10px 10px rgba(0, 62, 65, 0.7);
    background-color: #010214;
    padding: 0.5rem;
}
